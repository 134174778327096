import React, { useEffect, useState, useRef } from "react";
import SuperSideBar from "../SiderNavBar/Sidebar";
import AppHeader from "../../TopBar/AppHeader";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CAvatar,

} from "@coreui/react";
import { MDBInputGroup, MDBInput, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { getPartnerPending, updatePartnerAccess } from "../../../utils/api";
import AppLoader from "../../AppLoader";
import { toast } from "react-toastify";
import EmptyData from "../../EmptyData";

const PartnerAccess = () => {

 const [loading, setLoading] = useState(false);
  const [pendinglist, setPendingList] = useState([]);
  const [grantedlist, setGrantedList] = useState([]);
  const [pendingAccess, setPendingAccess] = useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(3);
  const [maxPage, setMaxPage] = React.useState(3);
  const [minPage, setMinPage] = React.useState(0);

  const [currentPage2, setCurrentPage2] = React.useState(1);
  const [pageLimit2, setPageLimit2] = React.useState(3);
  const [maxPage2, setMaxPage2] = React.useState(3);
  const [minPage2, setMinPage2] = React.useState(0);

  const [search, setSearch] = useState("");

  const [originalPendingList, setOriginalPendingList] = useState([]); 
  const [originalGrantedList, setOriginalGrantedList] = useState([]);

  const [isDisabled, setIsDisabled] = useState(true);
  const [status, setStatus] = useState("")


   const handleSearchChange = (e) => {
      const value = e.target.value.toLowerCase();
      setSearch(value);
  
      console.log("value ___", value, pendinglist, grantedlist);
  
      // Search in access_pending and access_granted arrays
      if (value) {
        const pendingMatch = pendinglist?.find(driver => driver.email.toLowerCase() === value);
        const grantedMatch = grantedlist?.find(driver => driver.email.toLowerCase() === value);
  
        if (pendingMatch) {
          console.log("Matched in access_pending:", pendingMatch);
          setIsDisabled(false)
          setOriginalPendingList(pendingMatch)
          setOriginalGrantedList([])
          setStatus("pending")
        } else if (grantedMatch) {
          console.log("Matched in access_granted:", grantedMatch);
          setIsDisabled(false)
          setOriginalGrantedList(grantedMatch)
          setOriginalPendingList([])
          setStatus("granted")
        } else {
          console.log("No match found");
          setIsDisabled(true);
          setOriginalGrantedList([]);
          setOriginalPendingList([]);
          setStatus("nomatch")
        }
      }
    };
  
  
    const accessList = () => {
      console.log("hello_______", originalGrantedList, originalPendingList);
      setSearch("");
      if (status === "granted") {
        toast.warning("This driver already has access to the account.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (status === "pending") {
        giveAccess(originalPendingList, 'INVOKED')
      } else {
        toast.error("The user does not exist.", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    }
  
  
    const filteredData = pendingAccess ? grantedlist : pendinglist;
    const recordPage = 10;
    const lastIndex = currentPage * recordPage;
    const firstIndex = lastIndex - recordPage;
    const data = filteredData?.slice(firstIndex, lastIndex) || [];
    const nPage = Math.ceil(filteredData?.length / recordPage);
    // const number = [...Array(nPage + 1).keys()]?.slice(1);
    const number = nPage > 0 ? [...Array(nPage + 1).keys()]?.slice(1) : [];

  
  
   
    const filteredData2 = grantedlist;
    console.log(filteredData2 , "fil")
    const recordPage2 = 10;
    const lastIndex2 = currentPage2 * recordPage2;
    const firstIndex2 = lastIndex2 - recordPage2;
    const data2 = filteredData2?.slice(firstIndex2, lastIndex2) || [];
    const nPage2 = Math.ceil(filteredData2?.length / recordPage2);
    // const number2 = [...Array(nPage2 + 1).keys()]?.slice(1);
    const number2 = nPage > 0 ? [...Array(nPage2 + 1).keys()]?.slice(1) : [];
  
  
  
  
    const pageNumber = number.map((num, i) => {
      if (num < maxPage + 1 && num > minPage) {
        return (
          <>
            <li
              key={i}
              className={currentPage == num ? `active_btn ` : `unactive_btn`}
            >
              <button onClick={() => changePage(num)}>{num}</button>
            </li>
          </>
        );
      } else {
        return null;
      }
    });
  
  
    const pageNumber2 = number2.map((num, i) => {
      if (num < maxPage2 + 1 && num > minPage2) {
        return (
          <>
            <li
              key={i}
              className={currentPage2 == num ? `active_btn ` : `unactive_btn`}
            >
              <button onClick={() => changePage2(num)}>{num}</button>
            </li>
          </>
        );
      } else {
        return null;
      }
    });
  
    const handlePrePage = () => {
      if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageLimit == 0) {
          setMaxPage(maxPage - pageLimit);
          setMinPage(minPage - pageLimit);
        }
      }
    };
  
    const handlePrePage2 = () => {
      if (currentPage2 !== 1) {
        setCurrentPage2(currentPage2 - 1);
        if ((currentPage2 - 1) % pageLimit2 == 0) {
          setMaxPage2(maxPage2 - pageLimit2);
          setMinPage2(minPage2 - pageLimit2);
        }
      }
    };
  
    const handleNextPage = () => {
      if (currentPage !== nPage) {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPage) {
          setMaxPage(maxPage + pageLimit);
          setMinPage(minPage + pageLimit);
        }
      }
    };
  
    const handleNextPage2 = () => {
      if (currentPage2 !== nPage2) {
        setCurrentPage2(currentPage2 + 1);
        if (currentPage2 + 1 > maxPage2) {
          setMaxPage2(maxPage2 + pageLimit2);
          setMinPage2(minPage2 + pageLimit2);
        }
      }
    };
  
    const changePage = (id) => {
      setCurrentPage(id);
    };
    let pageIncreament = null;
    if (data?.length > maxPage) {
      pageIncreament = <li onClick={handleNextPage}>&hellip;</li>;
    }
  
    const changePage2 = (id) => {
      setCurrentPage2(id);
    };
    let pageIncreament2 = null;
    if (data?.length > maxPage2) {
      pageIncreament2 = <li onClick={handleNextPage2}>&hellip;</li>;
    }
  
    useEffect(() => {
      getDriverList();
    }, [])
  
    const getDriverList = () => {
      setLoading(true);
      getPartnerPending().then((res) => {
        
        setPendingList(res.access_pending)
        setGrantedList(res.access_granted)
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        console.log("error in getting pending access drivers");
      })
    }
  
    const giveAccess = (list, status) => {
      setLoading(true);
      let data = { driver_id: list._id, status: status };
      updatePartnerAccess(data).then((res) => {
        console.log(res)
        getDriverList();
        toast.success(res?.message, {
          position: "top-right",
          autoClose: 1000,
        });
  
      }).catch(() => {
        setLoading(false);
      })
    }
  
    const revokeAccess = (list, status) => {
      setLoading(true);
      let data = { driver_id: list._id, status: status };
      updatePartnerAccess(data).then((res) => {
        console.log(res)
        getDriverList();
        toast.success(res?.message, {
          position: "top-right",
          autoClose: 1000,
        });
      }).catch(() => {
        setLoading(false);
      })
    }


  return (
    <>
    <div className="container-fluidd">
      <div className="col-md-12">
        <div>
          <SuperSideBar />

          <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <AppHeader />
            <div
              className="body flex-grow-1 px-3"
              style={{ paddingBottom: "20px" }}
            >
              <div className="mb-3 d-flex ">
                {/* <div><button className={`btn ${!pendingAccess ? "btn-warning" : "btn-light"}`} onClick={() => setPendingAccess(false)}>Pending-Access</button> <button className={`btn ${pendingAccess ? "btn-warning" : "btn-light"}`} onClick={() => setPendingAccess(true)}>Access-Granted</button> </div> */}
                <div className="serach-left" id="pending-trip-search">
                  <MDBInputGroup>
                    <MDBInput placeholder="Search Driver Email" value={search} onChange={handleSearchChange} style={{
                      width: '250px'
                    }}  />
                  </MDBInputGroup>
                </div>
                <div>
                  <button className="btn btn-warning ms-3  " style={{ height: '48px' }} onClick={accessList} disabled={!search} >Access-Granted</button>
                </div>
              </div>
              {loading ? <AppLoader /> : <div className="active-trip-outer" >
                {data2?.length > 0 ?
                  <CTable align="middle" className="mb-0" hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell className="text-center">Image</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Name</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Mobile No.</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Email</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody >
                      {data2?.length > 0 ? data2?.map((item, index) => (
                        <CTableRow className="text-center" key={index}>
                          <CTableDataCell className="text-center profle-pic">
                            <CAvatar size="md" alt='img' src={item.profile_image} style={{ width: 42, height: 42 }} />
                          </CTableDataCell>
                          <CTableDataCell>
                            <div>{item.first_name} {item?.last_name}</div>
                          </CTableDataCell>
                          <CTableDataCell>
                            <div>{item?.phone}</div></CTableDataCell>
                          <CTableDataCell><div>{item?.email}</div></CTableDataCell>
                          <CTableDataCell><div>  <button className='btn btn-warning' onClick={() => revokeAccess(item, 'REVOKED')}>Revoke Access</button></div></CTableDataCell>
                        </CTableRow>
                      )) : ""}</CTableBody>
                  </CTable> : <EmptyData />}
                {
                  data2?.length > 0 ?
                    <div
                      className="pagination-outer"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        className="prev_btn"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <button onClick={() => handlePrePage2()}>
                          Previous
                        </button>
                      </div>
                      <div className="previous-page">
                        <ul>
                          {pageNumber2.map((item, index) => (
                            <li key={item.id || index}>{item}</li> // Use a unique property like `item.id`
                          ))}
                          <button className="dots_btn">
                            {pageIncreament2} {/* Ensure this also has a `key` if it's iterating */}
                          </button>
                        </ul>
                      </div>
                      <div className="next_btn">
                        <button onClick={() => handleNextPage2()}>Next</button>
                      </div>
                    </div>
                    : ""
                }
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default PartnerAccess