import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from 'react-router-dom';
import BarCharts from "../../Charts/BarCharts";
import PieCharts from "../../Charts/PieCharts";
import backtovehicle from '../../../assets/images/left-arrow.png';
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CModal,
    CModalBody,
    CCardBody,
    CRow,
    CCard,
    CCol,
    CFormInput,
    CFormLabel,
    CModalHeader,
    CModalTitle,
} from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import moment from "moment";
import EmptyData from "../../EmptyData";
import SuperAdminSideBar from "../../SuperAdmin/Sidebar/SideBar";
import AppHeader from "../../TopBar/AppHeader";
import { getDriverById, getDriverDetails, TotalRevenueChartDriver } from "../../../utils/api";
import ReactPaginate from 'react-paginate';

const DriverChart = () => {

    const CompID = useParams().id;
    const [tripType, setTripsType] = useState("Pending");
    const [dateType, setDateType] = useState("all");
    const [showDate, setShowDate] = useState(false);
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [tripData, setTripData] = useState([]);
    const [Customer, setCustomer] = useState([]);
    const [Fare, setFare] = useState([]);
    const [DriverData, setDriverData] = useState([]);

    const [allPagination, setAllPagination] = useState();
    const [pageNumbers, setPageNumbers] = useState(1);
    const [debouncedSearch, setDebouncedSearch] = useState("");

    const [allPagination2, setAllPagination2] = useState();
    const [pageNumbers2, setPageNumbers2] = useState(1);
    const [debouncedSearch2, setDebouncedSearch2] = useState("");


    const handlePageClick = (selectedPage) => {
        // console.log("se", selectedPage)
        setPageNumbers(selectedPage.selected + 1)
    };


    // Trips Data Pagination Start

    const [currentPage, setCurrentPage] = React.useState(1);


    const recordPage = 10;
    const lastIndex = currentPage * recordPage;
    const firstIndex = lastIndex - recordPage;
    const data = tripData


    // Trips Data Pagination End


    // Fare Pagination start


    const [dateType2, setDateType2] = useState("this_year");
    const [showDate2, setShowDate2] = useState(false);
    const [toDate2, setToDate2] = useState('');
    const [fromDate2, setFromDate2] = useState('');
    const [chartRevenue, setChartRevenue] = useState([]);
    const [chartTripCount, setChartTripCount] = useState([]);
    const [tripBarChartResult, setTripBarChartResult] = useState([]);
    const [barType, setBarType] = useState("Months");
    const [Revenue, setRevenue] = useState([]);



    const TripsFilter = (event) => {
        setTripsType(event.target.value);
        setPageNumbers(1);
    }


    const DateFilter = (event) => {
        const selectedValue = event.target.value;
        setDateType(selectedValue);
        setPageNumbers(1);


        if (selectedValue != "dateRange") {
            setToDate('');
            setFromDate('');
        }
    };

    const DateFilter2 = (event) => {
        const selectedValue = event.target.value;
        setDateType2(selectedValue);
        // console.log(selectedValue);

        if (selectedValue != "dateRange") {
            setToDate2('');
            setFromDate2('');
        }
    };


    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange2 = (event) => {
        setToDate2(event.target.value);
    };

    const handleFromDateChange2 = (event) => {
        setFromDate2(event.target.value);
    };

    const handleGetdate = () => {
        // console.log("To Date:", toDate);
        // console.log("From Date:", fromDate);

        fetchCompanyDetails();
    };

    const handleGetdate2 = () => {
        // console.log("To Date:", toDate2);
        // console.log("From Date:", fromDate2);

        fetchChartData();
    };

    useEffect(() => {
        if (dateType2 === "dateRange") {
            setShowDate2(true);
            setBarType("Date")
        } else if (dateType2 !== "dateRange") {
            setShowDate2(false);
            setToDate2('');
            setFromDate2('');
            fetchChartData();

            if (dateType2 === "this_year") {
                setBarType("Months")
            } else if (dateType2 === "this_month") {
                setBarType("Date")
            } else if (dateType2 === "this_week") {
                setBarType("Date")
            }


        }
    }, [dateType2])

    useEffect(() => {
        fetchCompanyById();
    }, [])

    const fetchCompanyById = () => {
        getDriverById(CompID).then((response) => {
            // console.log("Company Details ID________:", response);
            setDriverData(response?.result)

        }).catch((error) => {
            console.error("Error fetching Company Details:", error);
        });
    }

    useEffect(() => {
        if (dateType === "dateRange") {
            setShowDate(true);
        } else if (dateType !== "dateRange") {
            setShowDate(false);
            setToDate('');
            setFromDate('');

            fetchCompanyDetails();
        }
    }, [tripType, dateType, pageNumbers])


    const fetchCompanyDetails = () => {
        let data = {
            driver_id: CompID,
            dateFilter: dateType,
            startDate: toDate,
            endDate: fromDate,
            page: pageNumbers,
            limit: 10,
        }
        getDriverDetails(tripType, data).then((response) => {
            // console.log("Company Details:", response);
            setTripData(response.result);
            setAllPagination(response?.metadata?.total);

        }).catch((error) => {
            console.error("Error fetching Company Details:", error);
        });
    }




    const fetchChartData = () => {

        let data = {
            dateFilter: dateType2,
            startDate: toDate2,
            endDate: fromDate2,
        }

        TotalRevenueChartDriver(CompID, data).then((response) => {
            console.log("Chart Details:", response,);


            setTripBarChartResult(response?.tripBarChartResult)
            setChartTripCount(response?.chartTripCount)
            setChartRevenue(response?.chartRevenue)


            const TripBarRevenue = [];
            response?.tripBarChartResult.forEach((item) => {
                TripBarRevenue.push({
                    label: item.label,
                    totalRevenue: item.totalRevenue
                });
            });

            // console.log("TripBarChartResult______", TripBarRevenue)
            setRevenue(TripBarRevenue);

        }).catch((error) => {
            console.error("Error fetching Company Details:", error);
        });
    }



    return (
        <>
            <div className='container-fluidd'>
                <div className="col-md-12">
                    <div>
                        <SuperAdminSideBar />
                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                            <AppHeader />
                            <div className="body flex-grow-1 px-3">
                                <h1 className="heading-for-every-page">
                                    <Link to={'/super-admin/driver/listofdrivers'}>
                                        <img src={backtovehicle} alt="edit" /> Back
                                    </Link>
                                </h1>


                                {/* Chart Details Start  */}
                                <div className='ChartDetail mt-5'>
                                    <h5 className='ps-4'>Chart Details of {DriverData?.first_name}</h5>
                                    <div className='active-trip-outer mx-4'>


                                        <div className='row'>
                                            <div className='col-md-12 mb-3'>
                                                <div className="chart_div  ms-2 p-3">
                                                    <h4 className='text-center'>Completed Trips with Payment</h4>
                                                    <div className='d-flex'>
                                                        <select className='ms-4 select_custom2' value={dateType2}
                                                            onChange={DateFilter2}>
                                                            <option value="this_year">This year</option>
                                                            <option value="this_month">This Month</option>
                                                            <option value="this_week">This Week</option>
                                                            <option value="dateRange">Date Range</option>
                                                        </select>

                                                        {
                                                            showDate2 ?
                                                                (
                                                                    <div className="ms-5 ps-3 d-flex align-items-center">
                                                                        <label className="me-3">To</label>
                                                                        <input
                                                                            type="date"
                                                                            className="input_custom2 me-3"
                                                                            value={toDate2}
                                                                            onChange={handleToDateChange2}
                                                                        />
                                                                        <label className="me-3">From</label>
                                                                        <input
                                                                            type="date"
                                                                            className="input_custom2"
                                                                            value={fromDate2}
                                                                            onChange={handleFromDateChange2}
                                                                        />
                                                                        <button className="btn btn-primary ms-3" onClick={handleGetdate2}>
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                ) : ''
                                                        }
                                                    </div>
                                                    <BarCharts width={100} height={350} data={tripBarChartResult} typex={barType} typey={'Total Trips'} />
                                                </div>
                                            </div>

                                            <div className='col-md-12'>
                                                <div className="chart_div mt-2 ms-2 p-4">
                                                    <h4 className='text-center'>Total Revenue</h4>
                                                    <BarCharts width={100} height={350} data={Revenue} typex={barType} typey={'Total Revenue'} />

                                                </div>
                                            </div>


                                            <div className='col-md-12'>
                                                <div className="chart_div mt-2 ms-2 p-4">
                                                    <PieCharts width={100} height={270} data={chartTripCount} name={'Trips Count'} />
                                                </div>
                                            </div>

                                            <div className='col-md-12'>
                                                <div className="chart_div mt-2 ms-2 p-4">
                                                    <PieCharts width={100} height={270} data={chartRevenue} name={'Trips Revenue'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Chart Details End  */}


                                {/* Trip Details Start  */}

                                <div className='TripDetail'>
                                    <h5 className='ps-4'>Trips Details of {DriverData?.first_name}</h5>
                                    <div className='active-trip-outer mx-4'>
                                        <div className='d-flex'>
                                            <select className='ms-4 select_custom'
                                                value={tripType}
                                                onChange={TripsFilter}>
                                                <option value="Pending">Pending Trips</option>
                                                <option value="Booked">Booked Trips</option>
                                                <option value="Completed">Completed Trips</option>
                                                <option value="Canceled">Cancelled Trips</option>
                                            </select>
                                            <select className='ms-4 select_custom'
                                                value={dateType}
                                                onChange={DateFilter}>
                                                <option value="all">All</option>
                                                <option value="this_week">This Week</option>
                                                <option value="this_month">This Month</option>
                                                <option value="this_year">This year</option>
                                                <option value="dateRange">Date Range</option>
                                            </select>

                                            {
                                                showDate ?
                                                    (
                                                        <div className="ms-5 ps-3 d-flex align-items-center">
                                                            <label className="me-3">To</label>
                                                            <input
                                                                type="date"
                                                                className="input_custom me-3"
                                                                value={toDate}
                                                                onChange={handleToDateChange}
                                                            />
                                                            <label className="me-3">From</label>
                                                            <input
                                                                type="date"
                                                                className="input_custom"
                                                                value={fromDate}
                                                                onChange={handleFromDateChange}
                                                            />
                                                            <button className="btn btn-primary ms-3" onClick={handleGetdate}>
                                                                Submit
                                                            </button>
                                                        </div>
                                                    ) : ''
                                            }

                                        </div>


                                        {data?.length > 0 ? (

                                            <CTable
                                                align="middle"
                                                className="mb-0 mt-3"
                                                hover
                                                responsive>
                                                <CTableHead>
                                                    <CTableRow>
                                                        <CTableHeaderCell>
                                                            Sr. No.
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell>
                                                            Trip id
                                                        </CTableHeaderCell>
                                                        {/* <CTableHeaderCell>
                                                    Customer Name
                                                </CTableHeaderCell> */}
                                                        <CTableHeaderCell>
                                                            Trip From
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell>
                                                            Trip To
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell>
                                                            Comment
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell>
                                                            Date
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">View Ride</CTableHeaderCell>

                                                    </CTableRow>
                                                </CTableHead>

                                                <CTableBody>
                                                    {
                                                        data?.length > 0 ? data?.map((item, index) => (
                                                            <CTableRow

                                                                v-for="item in tableItems"
                                                                key={item._id}
                                                            >
                                                                <CTableDataCell>
                                                                    <div>{firstIndex + index + 1}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item.trip_id}</div>
                                                                </CTableDataCell>
                                                                {/* <CTableDataCell>
                                                            <div>{item.trip_id}</div>
                                                        </CTableDataCell> */}
                                                                <CTableDataCell>
                                                                    <div>{item?.trip_from?.address?.slice(0, 20) + `${item?.trip_from?.address?.length < 21 ? "" : "..."}`}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item?.trip_to?.address?.slice(0, 20) + `${item?.trip_to?.address?.length < 21 ? "" : "..."}`}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item.trip_to?.comment}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>
                                                                        {moment(item.pickup_date_time).format(
                                                                            "MMMM Do YYYY, h:mm a"
                                                                        )}
                                                                    </div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div className="view_details_btn">
                                                                        <Link to={`/trips/view-trip-details/${item._id}`}>
                                                                            View Details
                                                                        </Link>
                                                                    </div>
                                                                </CTableDataCell>

                                                            </CTableRow>
                                                        )) : ''
                                                    }
                                                </CTableBody>
                                            </CTable>) : <EmptyData />}
                                        <div className='pagination'>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={1}
                                                pageCount={Math.ceil(allPagination / 10)}
                                                previousLabel="<"
                                                renderOnZeroPageCount={null}
                                            />
                                        </div>


                                    </div>
                                </div>

                                {/* Trip Details End */}


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DriverChart