import React, { useEffect, useState } from "react";
import AppHeader from "../../TopBar/AppHeader";
import { Row, Col, Card } from "react-bootstrap";

import {
  CButton,
  CModal,
  CModalBody,
  CCardBody,
  CCol,
  CRow,
  CCard,
} from "@coreui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import vehicle1 from '../../../assets/images/vehicle1.png';
import {
  deleteDriver,
  deleteVehicle,
  getDriverVehicle,
  getVehicle,
} from "../../../utils/api";
import PulseLoader from "react-spinners/PulseLoader";
// import SuperSideBar from "../SiderNavBar/Sidebar";
import editvehicleicon from "../../../assets/images/editvehi.png";
import deletevehicleicon from "../../../assets/images/deletevehi.png";
import deletepopup from "../../../assets/images/deletepopup.png";
import { toast } from "react-toastify";
import EmptyData from "../../EmptyData";
import SuperAdminSideBar from "../../SuperAdmin/Sidebar/SideBar";
import AppLoader from "../../AppLoader";
import SuperSideBar from "../../Taxi/SiderNavBar/Sidebar";
import ReactPaginate from 'react-paginate';
import moment from "moment";

const DriverVehicle = ({ role }) => {
  const [visible, setVisible] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [activeTrip, setActiveTrip] = useState([]);
  const [defaultVehicle, setDefaultVehicle] = useState("");

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(3);
  const [maxPage, setMaxPage] = React.useState(3);
  const [minPage, setMinPage] = React.useState(0);
  const recordPage = 10;
  const lastIndex = currentPage * recordPage;
  const firstIndex = lastIndex - recordPage;
  const data = vehicle?.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(vehicle?.length / recordPage);
  const number = [...Array(nPage + 1).keys()].slice(1);

  const pageNumber = number.map((num, i) => {
    if (num < maxPage + 1 && num > minPage) {
      return (
        <>
          <li
            key={i}
            className={currentPage == num ? `active_btn ` : `unactive_btn`}
          >
            <button onClick={() => changePage(num)}>{num}</button>
          </li>
        </>
      );
    } else {
      return null;
    }
  });

  const handlePrePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      if ((currentPage - 1) % pageLimit == 0) {
        setMaxPage(maxPage - pageLimit);
        setMinPage(minPage - pageLimit);
      }
    }
  };

  const handleNextPage = () => {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
      if (currentPage + 1 > maxPage) {
        setMaxPage(maxPage + pageLimit);
        setMinPage(minPage + pageLimit);
      }
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  let pageIncreament = null;
  if (data.length > maxPage) {
    pageIncreament = <li onClick={handleNextPage}>&hellip;</li>;
  }

  const navigate = useNavigate();

  const params = useParams();
  const id = params.id;
  useEffect(() => {
    setLoader(true);
    getDriverVehicle(id).then((res) => {
      console.log(res, "vehicle result");
      if (res?.code === 200) {
        setVehicle(res?.result);
        const defaultid = res?.defaulVehicle;
        const defaultVehicle = res?.result.filter((d) => d._id === defaultid);
        console.log(defaultVehicle, "default vehicle");
        if (defaultVehicle.length > 0) {
          setDefaultVehicle(defaultVehicle[0]);
        }
      }
      setLoader(false);
    });
  }, []);

  console.log("default vehicle +++", defaultVehicle);

  const deleteVehicleHandler = async () => {
    try {
      console.log(selectedId, "vehicle deleted id");
      const deleteData = await deleteVehicle(selectedId);
      console.log(deleteData, "delete vehicle data");
      if (deleteData.code === 200) {
        setVisible(false);
        toast.success(`${deleteData.message}`, {
          position: "top-right",
          autoClose: 1000,
        });
        const newData = vehicle.filter((d) => d._id != selectedId);
        setVehicle(newData);
      } else {
        toast.warning(`${deleteData.message}`, {
          position: "top-right",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container-fluid px-0">
        <div className="col-md-12">
          <div>
            {role == "super" ? <SuperAdminSideBar /> : <SuperSideBar />}
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              <AppHeader />
              <div
                className="body flex-grow-1 px-3"
                style={{ paddingBottom: "20px" }}
              >
              
                  
                <div className="d-flex justify-content-between me-5">
                <h1 className="heading-for-every-page">Vehicles List</h1>
                  <Link to={`/super-admin/addnewvehicle/${id}`}>
                  <CButton className=" submit-btn">Add Vehicle</CButton>
                  </Link>
                </div>

               
                
               
                <div className="active-trip-outer">
                  {/* <h2>View All Vehicles</h2> */}

                  <div className="p-4 vehicle-cards">
                    {loader ? (
                      <>
                        <AppLoader />
                      </>
                    ) : (
                      <>


                        <div className="row">
                          {
                            data.length > 0 ? (

                              data.map((data, i) => {
                                return (


                                  <div className="col-md-12 mb-4">
                                    <div className="border border-1 rounded-4 p-3 d-flex justify-content-between align-items-center" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", flexWrap: "wrap" }}>
                                      <img src={data.vehicle_photo} height={"115px"} width={"115px"} />
                                      <div className="d-flex">
                                        <span className="me-3">
                                          <p className="head_veh mb-3">Vehicle Type</p>
                                          <p className="head_veh mb-3">Vehicle Number</p>
                                          <p className="head_veh mb-3">Seating Capacity</p>
                                          <p className="head_veh mb-3">Vehicle  Modal</p>
                                        </span>
                                        <span>
                                          <p className="head_veh2 mb-3">{data.vehicle_type}</p>
                                          <p className="head_veh2 mb-3">{data.vehicle_number}</p>
                                          <p className="head_veh2 mb-3">{data.seating_capacity} Persons</p>
                                          <p className="head_veh2 mb-3">{data.vehicle_model}</p>
                                        </span>

                                      </div>

                                      <div className="d-flex">
                                        <span className="me-3">
                                          <p className="head_veh mb-3">Vehicle Make</p>
                                          <p className="head_veh mb-3">AC</p>
                                          <p className="head_veh mb-3">Insurance renewal Date</p>
                                          <p className="head_veh mb-3">Price per km</p>
                                        </span>
                                        <span>
                                          <p className="head_veh2 mb-3">{data.vehicle_make}</p>
                                          <p className="head_veh2 mb-3">{data?.AC.toString()}</p>
                                          <p className="head_veh2 mb-3"> {moment(data?.insurance_renewal_date).format(
                                            "MMMM Do YYYY, h:mm a"
                                          )} </p>
                                          <p className="head_veh2 mb-3">{data.price_per_km}</p>
                                        </span>

                                      </div>

                                      <div className="h-100">

                                        {role == "super" && (
                                          <div className=" d-flex flex-column ">
                                            <Link
                                              to={`/super-admin/vehicle/editvehicle/${data._id}`}
                                            >
                                              <CButton
                                                id="btn_edit_vehicle"
                                                className="edit_vehicle p-0 border-0 bg-transparent"
                                              >
                                                <img
                                                  src={editvehicleicon}
                                                  alt="edit-icon"
                                                />
                                              </CButton>
                                            </Link>

                                            <CButton
                                              id="btn_delete_vehicle"
                                              className="delete_vehilce p-0 border-0 bg-transparent mt-3"
                                              onClick={() => {
                                                setVisible(!visible);
                                                setSelectedId(data._id);
                                              }}
                                            >
                                              <img
                                                src={deletevehicleicon}
                                                alt="edit-icon"
                                                className="rounded-circle"
                                                style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}
                                              />
                                            </CButton>

                                            <CButton id="btn_delete_vehicle"
                                              className="delete_vehilce p-0 border-0 bg-transparent mt-2 rounded-circle"
                                              style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px", height: '40px', width: '40px' }}>


                                              <Link to={`/${role == "taxi" ? "taxi" : "super-admin"
                                                }/vehicle/vehicle-details/${data._id}`}

                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  fill="currentColor"
                                                  className="bi bi-eye-fill"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                                </svg>
                                              </Link>
                                            </CButton>
                                          </div>
                                        )}

                                      </div>

                                    </div>
                                  </div>

                                )

                              })


                            ) : (<EmptyData />)
                          }
                        </div>


                      </>
                    )}

                    {/* StartDeletepopup */}

                    <CModal
                      alignment="center"
                      visible={visible}
                      onClose={() => setVisible(false)}
                    >
                      {/* <CModalHeader>
                      <CModalTitle>Edit Fare</CModalTitle>
                    </CModalHeader> */}
                      <CModalBody>
                        <CRow>
                          <CCol xs={12}>
                            <CCard className="mb-4 delete_vehicle_popup">
                              <CCardBody>
                                <img src={deletepopup} alt="danger" />
                                <h2>Are you Sure</h2>
                                <p>You want to delete this Vehicle ?</p>
                              </CCardBody>
                              <div className="delete_vehicle_popup_outer">
                                <CButton
                                  className="delete_popup"
                                  onClick={() => {
                                    deleteVehicleHandler();
                                  }}
                                >
                                  Delete
                                </CButton>
                                <CButton
                                  className="cancel_popup"
                                  onClick={() => setVisible(false)}
                                >
                                  Cancel
                                </CButton>
                              </div>
                            </CCard>
                          </CCol>
                        </CRow>
                      </CModalBody>
                    </CModal>

                    {/* enddeletepopup */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriverVehicle;
