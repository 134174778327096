import React, { useState, useEffect } from "react";
import AppHeader from "../../TopBar/AppHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { useNavigate, useParams } from "react-router-dom";
//import background from '../assets/images/heroimg.png';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormCheck,
  CRow,
} from '@coreui/react'
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { addVehicle, getVehicleById, getVehicleType } from "../../../utils/api";
import uploadfileImg from '../../../assets/images/upload-btn.png'
import SuperSideBar from "../SiderNavBar/Sidebar";
import SuperAdminSideBar from "../../SuperAdmin/Sidebar/SideBar";


const AddSuperVehicle = () => {


  const navigate = useNavigate();
  const [vehicleinsuranceDate, setInsuranceDate] = useState(new Date()); // Initialize with the current date for insurance renewal date
 const [doc, setDoc] = useState("");
  const [selectedDoc, setSelectedDoc] = useState(null);
  const initialValues = {
    vehicleNo: "",
    vehicleType: "",
    vehicleModal: "",
    seatingCapacity: "",
    vehicleMake: "",
    // pricePerKm: "",
    // minimumFare: "",
    // commission: "",
    passengerTimeLimit: "",
    passengerCharges: "",
    vehicleinsuranceDate: new Date(),
    file: "",
    AC: '',
    doc:'',
  };

  const validationSchema = Yup.object().shape({
    vehicleNo: Yup.string().trim().required("Vehicle No is required"),
    vehicleType: Yup.string().trim().required("Vehicle Type is required"),
    vehicleModal: Yup.string().trim().required("Vehicle Model is required"),
    vehicleMake: Yup.string().trim().required("Vehicle Make is required"),
    seatingCapacity: Yup.string().trim().matches(/^[0-9]+$/, "Must be only digits").required("Seating Capacity is required"),
    // pricePerKm: Yup.string().required("Price per km is required"),
    // minimumFare: Yup.string().required("Minimum Fare is required"),
    // commission: Yup.string().required("Commission is required"),
    passengerTimeLimit: Yup.string().trim().matches(/^[0-9]+$/, "Must be only digits").required("Passenger Time Limit is required"),
    passengerCharges: Yup.number().required("Passenger Cancellation Charges is required").max(10000000,"Passenger Charges must be less than 10000000"),
    vehicleinsuranceDate: Yup.date().required("Insurance Renewal Date is required"),
    file: Yup.mixed().required("Vehicle Image is required"),
    AC: Yup.string().trim().required("AC type is required"),
     doc: Yup.mixed().required("Driver's Document is required"),
  });
  const handleMobile = (event, max) => {
    const pattern = /^[0-9]+$/;
    if (
      event.key === "Backspace" ||
      event.key === "Enter" ||
      event.key === "Tab" ||
      event.key === "Shift" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight"
    ) {
      formik.setFieldValue(event.target.name, event.target.value);
      formik.setFieldTouched(event.target.name, true);
    } else {
      let value = event.target.value.toString();
      if (value.length > max) {
        event.stopPropagation();
        event.preventDefault();
      } else {
        if (!pattern.test(event.key)) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          formik.setFieldValue(event.target.name, event.target.value);
          formik.setFieldTouched(event.target.name, true);
        }
      }
    }
  };
  const [vehicleType, setVehicleType] = useState([])

  const [selectedAC, setSelectedAC] = useState('');

  const [image, setImage] = useState(null);
  const params = useParams();
  const id = params.id;

  const handleACtype = (event) => {
    setSelectedAC(event.target.value);
    console.log(event.target.value);
    formik.setFieldValue('AC', event.target.value)
  };

  useEffect(() => {

    getVehicleType("Active").then(res => {
      console.log(res?.result, 'vehicle')
      if (res?.code === 200) {
        setVehicleType(res.result)
      }
    })

  }, []);

  const back = () => {
    navigate(`/super-admin/driver/driver-vehicle/${id}`)
  }


  const handlevehicleInsuranceDateChange = (date) => {
    setInsuranceDate(date);
    formik.setFieldValue('vehicleinsuranceDate', date)
  };

  const uploadFile = (e) => {
    const selectedFile = e.target.files[0];
    formik.setFieldValue('file', selectedFile)
    setImage(URL.createObjectURL(selectedFile))


  }

  const uploadDoc = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setSelectedDoc(selectedFile);
      formik.setFieldValue("doc", selectedFile);
      setDoc(URL.createObjectURL(selectedFile));
    }
  };

  const removeDoc = (e) => {
    setSelectedDoc(null);
    formik.setFieldValue("doc", "");
    setDoc(null);
  };

  function previewPDF() {
    const file = selectedDoc;

    if (file && file.type === "application/pdf") {
      const reader = new FileReader();

      reader.onload = function (e) {
        const pdfData = e.target.result;

        // Open a new tab with the PDF content
        const newTab = window.open();
        newTab.document.write(
          '<embed width="100%" height="100%" src="data:application/pdf;base64,' +
            window.btoa(pdfData) +
            '" type="application/pdf" />'
        );
      };

      reader.readAsBinaryString(file);
    } else {
      alert("Please upload a valid PDF file.");
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);

      const formData = new FormData();

      formData.append('vehicle_number', values.vehicleNo);
      formData.append('vehicle_type', values.vehicleType);
      formData.append('vehicle_model', values.vehicleModal);
      formData.append('vehicle_make', values.vehicleMake);
      formData.append('seating_capacity', values.seatingCapacity);
      // formData.append('price_per_km', values.pricePerKm);
      // formData.append('minimum_fare', values.minimumFare);
      // formData.append('commision', values.commission);
      formData.append('cancelation_time_limit', values.passengerTimeLimit);
      formData.append('cancelation_charges', values.passengerCharges);
      formData.append('insurance_renewal_date', values.vehicleinsuranceDate);
      formData.append('vehicle_photo', values.file)
      formData.append('AC', values.AC)
      formData.append("driver_documents", values.doc);

      
      addVehicle(id ,formData).then((res) => {
        console.log("response---->>>>", res)
        if (res.code === 200) {
          toast.success(`${res.message}`, {
            position: 'top-right',
            autoClose: 1000,
          });
          back()
        } else {
          toast.warning(`${res.message}`, {
            position: 'top-right',
            autoClose: 1000,
          });
        }
      })


    },
  });

  return (
    <>
      <div className="container-fluidd">

        <div className="col-md-12">

          <div>
            <SuperAdminSideBar />

            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              <AppHeader />
              <div className="body flex-grow-1 px-3" style={{ paddingBottom: "20px" }}>
                <h1 className="heading-for-every-page">Add New Vehicle</h1>
                <div className="active-trip-outer">
                  {/* <h2>Add New Vehicle</h2> */}
                  {/********** vehicle---information---form *****************/}
                  <CRow>

                    <CCol xs={12}>
                      <CCard className="mb-4">
                        <CCardHeader>
                          <strong>Vehicle Information</strong>
                        </CCardHeader>
                        <CCardBody>

                          <form onSubmit={formik.handleSubmit} noValidate className="row g-3">

                            <CCol md={6}>
                              <CFormLabel htmlFor="inputvehiclenum">Vehicle No.<span className="asterisk-mark">*</span></CFormLabel>
                              <CFormInput aria-label="vehicle no."  {...formik.getFieldProps("vehicleNo")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.vehicleNo && formik.errors.vehicleNo,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.vehicleNo && !formik.errors.vehicleNo,
                                  }
                                )}
                                name="vehicleNo"
                                autoComplete="off" />
                              {formik.errors.vehicleNo && formik.touched.vehicleNo ? (
                                <div className="text-danger">{formik.errors.vehicleNo}</div>
                              ) : null}
                            </CCol>

                            <CCol md={6}>
                              <CFormLabel htmlFor="inputvehicletype">Vehicle Type<span className="asterisk-mark">*</span></CFormLabel>
                              <CFormSelect  {...formik.getFieldProps("vehicleType")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.vehicleType && formik.errors.vehicleType,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.vehicleType && !formik.errors.vehicleType,
                                  }
                                )}
                                name="vehicleType"
                                autoComplete="off" >

                                <option default>Select Vehicle Type</option>
                                {vehicleType?.map((e, i) => {
                                  return (
                                    <>
                                      <option value={e.name} >{e.name}</option>
                                    </>
                                  )
                                })}
                              </CFormSelect>
                              {formik.errors.vehicleType && formik.touched.vehicleType ? (
                                <div className="text-danger">{formik.errors.vehicleType}</div>
                              ) : null}
                            </CCol>

                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputvehivlemodal">Vehicle Make <span className="asterisk-mark">*</span></CFormLabel>
                              <CFormInput   {...formik.getFieldProps("vehicleMake")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.vehicleMake && formik.errors.vehicleMake,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.vehicleMake && !formik.errors.vehicleMake,
                                  }
                                )}
                                name="vehicleMake"
                                autoComplete="off" />
                              {formik.errors.vehicleMake && formik.touched.vehicleMake ? (
                                <div className="text-danger">{formik.errors.vehicleMake}</div>
                              ) : null}
                            </CCol>
<CCol xs={6}>
                              <CFormLabel htmlFor="inputvehivlemodal">Vehicle Model<span className="asterisk-mark">*</span></CFormLabel>
                              <CFormInput   {...formik.getFieldProps("vehicleModal")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.vehicleModal && formik.errors.vehicleModal,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.vehicleModal && !formik.errors.vehicleModal,
                                  }
                                )}
                                name="vehicleModal"
                                autoComplete="off" />
                              {formik.errors.vehicleModal && formik.touched.vehicleModal ? (
                                <div className="text-danger">{formik.errors.vehicleModal}</div>
                              ) : null}
                            </CCol>

                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputseating">Seating Capacity<span className="asterisk-mark">*</span></CFormLabel>
                              <CFormInput onKeyDown={(e) => {
                                handleMobile(e, 17);
                              }}  {...formik.getFieldProps("seatingCapacity")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.seatingCapacity && formik.errors.seatingCapacity,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.seatingCapacity && !formik.errors.seatingCapacity,
                                  }
                                )}
                                name="seatingCapacity"
                                autoComplete="off" />
                              {formik.errors.seatingCapacity && formik.touched.seatingCapacity ? (
                                <div className="text-danger">{formik.errors.seatingCapacity}</div>
                              ) : null}
                            </CCol>

                            

                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputpassenger">Passenger Cancellation Time Limit (in Minutes)<span className="asterisk-mark">*</span></CFormLabel>
                              <CFormInput id="inputpassengertimelimit" onKeyDown={(e) => {
                                handleMobile(e, 17);
                              }}  {...formik.getFieldProps("passengerTimeLimit")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.passengerTimeLimit && formik.errors.passengerTimeLimit,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.passengerTimeLimit && !formik.errors.passengerTimeLimit,
                                  }
                                )}
                                name="passengerTimeLimit"
                                autoComplete="off" />
                              {formik.errors.passengerTimeLimit && formik.touched.passengerTimeLimit ? (
                                <div className="text-danger">{formik.errors.passengerTimeLimit}</div>
                              ) : null}
                            </CCol>
                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputpassengercharges">Passenger Cancellation Charges (in € ) <span className="asterisk-mark">*</span></CFormLabel>
                              <CFormInput id="inputpassengercharges"
                                type="number"
                                {...formik.getFieldProps("passengerCharges")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.passengerCharges && formik.errors.passengerCharges,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.passengerCharges && !formik.errors.passengerCharges,
                                  }
                                )}
                                name="passengerCharges"
                                autoComplete="off" />
                              {formik.errors.passengerCharges && formik.touched.passengerCharges ? (
                                <div className="text-danger">{formik.errors.passengerCharges}</div>
                              ) : null}
                            </CCol>

                            <CCol md={6} className="date_pic">
                              <CFormLabel htmlFor="inputinsurancedate">Insurance Renewal Date</CFormLabel><br />
                              <DatePicker
                                selected={vehicleinsuranceDate}
                                onChange={handlevehicleInsuranceDateChange}
                                dateFormat="MM/dd/yyyy"
                                minDate={new Date()}

                                // {...formik.getFieldProps("vehicleinsuranceDate")}
                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.vehicleinsuranceDate && formik.errors.vehicleinsuranceDate,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.vehicleinsuranceDate && !formik.errors.vehicleinsuranceDate,
                                  }
                                )}
                                name="vehicleinsuranceDate"
                                autoComplete="off" />
                              {formik.errors.vehicleinsuranceDate && formik.touched.vehicleinsuranceDate ? (
                                <div className="text-danger">{formik.errors.vehicleinsuranceDate}</div>
                              ) : null}




                            </CCol>

                            <CCol md={6}>
                              <CFormLabel htmlFor="inputgender" >Select AC Type <span className="asterisk-mark">*</span></CFormLabel>
                              <fieldset className="row mb-12">
                                <CCol sm={12} className="mt-3">
                               
                                  <CFormCheck inline
                                    type="radio"
                                    name="gridRadios"
                                    id="gridRadios1"
                                    value="true"
                                    label="AC"
                                    onChange={handleACtype} 
                                    
                                    // Add the onChange event handler
                                    checked={selectedAC === 'true'} // Set the checked state if Male is selected
                                  />
                                  <CFormCheck inline
                                    type="radio"
                                    name="gridRadios"
                                    id="gridRadios2"
                                    value="false"
                                    label="NON-AC"
                                    onChange={handleACtype} // Add the onChange event handler
                                    checked={selectedAC === 'false'} // Set the checked state if Female is selected
                                  />
                                </CCol>
                              </fieldset>
                              {formik.errors.AC && formik.touched.AC ? (
                                <div className="text-danger">{formik.errors.AC}</div>
                              ) : null}
                            </CCol>

                            <CCol md={6} className="upload-file-input">
                              <CFormLabel htmlFor="inputmobile">Upload Vehicle Image<span className="asterisk-mark">*</span></CFormLabel>
                              <CFormLabel htmlFor="formFile"></CFormLabel>
                              {image?.length > 0 ?
                                (
                                  <img src={image} alt='img' height={300} width={100} />
                                ) :
                                ""}
                              <CFormInput accept="image/*" type="file" id="formFile" onChange={(e) => { uploadFile(e) }}

                                maxLength="50"
                                className={clsx(
                                  "form-control bg-transparent",
                                  {
                                    "is-invalid":
                                      formik.touched.file && formik.errors.file,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.file && !formik.errors.file,
                                  }
                                )}
                                name="file"
                                autoComplete="off" />
                              { !image && formik.errors.file && formik.touched.file ? (
                                <div className="text-danger">{formik.errors.file}</div>
                              ) : null}
                              <label htmlFor="formFile" className="custom-file-upload">
                                <div className="files-outer">
                                  <img className="upload-icon" src={uploadfileImg} /><br /><br />
                                  <span>Drop Image Here ...</span>
                                </div>
                              </label>
                            </CCol>

                            <CCol
                              md={6}
                              className="upload-file-input driver-docs"
                            >
                              <CFormLabel htmlFor="inputmobile">
                                Upload Driver Doc in PDF
                                <span className="asterisk-mark">*</span>
                              </CFormLabel>

                              {/* {doc?.length > 0 ?
                                (
                                  <embed src={doc} height={300} width={100} />
                                ) :
                                ""} */}
                              {selectedDoc && (
                                <div className="pdf_doc">
                                  <p>Selected file: {selectedDoc.name}</p>
                                  <button
                                    className="remove-btn"
                                    onClick={() => {
                                      removeDoc();
                                    }}
                                  >
                                    X
                                  </button>
                                  <button
                                    className="submit-btn"
                                    type="button"
                                    onClick={previewPDF}
                                  >
                                    Preview
                                  </button>
                                </div>
                              )}
                              {!selectedDoc && (
                                <>
                                  <CFormInput
                                    type="file"
                                    accept=".pdf"
                                    id="formFile"
                                    onChange={(e) => {
                                      uploadDoc(e);
                                    }}
                                    className={clsx(
                                      "form-control bg-transparent",
                                      {
                                        "is-invalid":
                                          formik.touched.doc &&
                                          formik.errors.doc,
                                      },
                                      {
                                        "is-valid":
                                          formik.touched.doc &&
                                          !formik.errors.doc,
                                      }
                                    )}
                                    name="doc"
                                    autoComplete="off"
                                  />
                                  {formik.errors.doc && formik.touched.doc ? (
                                    <div className="text-danger">
                                      {formik.errors.doc}
                                    </div>
                                  ) : null}
                                  <label
                                    htmlFor="formFile"
                                    className="custom-file-upload"
                                  >
                                    <div className="files-outer">
                                      <img
                                        className="upload-icon"
                                        src={uploadfileImg}
                                        alt="img"
                                      />
                                      <br />
                                      <br />
                                      <span>Drop Document Here ...</span>
                                    </div>
                                  </label>
                                </>
                              )}
                            </CCol>

                            <CCol xs={12}>
                              <div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
                                <CButton type="submit" className="submit-btn">Submit</CButton>
                                <CButton type="button" className="cancel-btn" onClick={back}>Cancel</CButton>
                              </div>
                            </CCol>
                          </form>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>

    </>
  );
};

export default AddSuperVehicle; 