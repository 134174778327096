import React, { useEffect, useState, useRef } from "react";
import Header from "../Header/Header";
import { Link } from "react-router-dom";

const PricingHome = () => {
    
      const [month, setMonth] = useState("Month");
        const [plan, setPlan] = useState("Premium");

  return (
     <>
            <div className="container-fluidd">
            <Header />
                <div className="col-md-10 mx-auto">
                    <div>
                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                            <div
                                className="body flex-grow-1 px-3"
                                style={{ paddingBottom: "20px" }}
                            >

                                <div className='row'>

                                    <div className='col-md-8'>
                                        <h1 className='mt-1'>Plans Built For Everyone</h1>
                                        <h5 className="mt-3">Get Started Now</h5>
                                    </div>

                                    <div className='col-md-4'>
                                        <h3 className='mt-3'>Subscription Plan</h3>
                                        <div className='mt-3 packge'>
                                            <span className={`me-2 ${month === "Month" ? "chng_pack" : "form_pack"}`} onClick={() => { setMonth("Month") }}>Monthly</span>
                                            <span className={`  ${month === "Year" ? "chng_pack" : "form_pack"}`} onClick={() => { setMonth("Year") }}>Yearly</span>
                                        </div>
                                    </div>

                                </div>


                                <div className='row mt-5'>

                                    <div className='col-md-4'>
                                        <div className={`plans_div ${plan === "Basic" ? "plan_Active" : "plan_simple"}`} onClick={() => { setPlan("Basic") }}>
                                            <div className="" >
                                                <h2>Basic</h2>
                                                <div className="d-flex">
                                                    <h5 className="me-1">${month === "Month" ? "60" : "240"}  </h5>/ <h5 className="ms-1">{month === "Month" ? "Month" : "Year"}</h5>
                                                </div>
                                                <div className="text-center">
                                                   <Link to="/register"> <button className={`btn ${plan === "Basic" ? "buy_active" : "buy_simple"}`} >
                                                        Buy now
                                                    </button>
                                                    </Link>
                                                </div>
                                                <div className={`${plan === "Basic" ? "hr_active" : "hr_simple"}`} > </div>

                                                <div className="offers">
                                                    <ul>
                                                        <li>
                                                            Limited drivers
                                                        </li>
                                                        <li>
                                                            Limited trips
                                                        </li>
                                                        <li>
                                                            Limited bookings
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className={`plans_div ${plan === "Premium" ? "plan_Active" : "plan_simple"}`} onClick={() => { setPlan("Premium") }}>
                                            <div className="popular">
                                                <h6 className="mb-0">Most Popular</h6>
                                            </div>
                                            <div className="">
                                                <h2>Premium</h2>
                                                <div className="d-flex">
                                                    <h5 className="me-1">${month === "Month" ? "80" : "300"}  </h5>/ <h5 className="ms-1">{month === "Month" ? "Month" : "Year"}</h5>
                                                </div>
                                                <div className="text-center">
                                                <Link to="/register">   <button className={`btn ${plan === "Premium" ? "buy_active" : "buy_simple"}`}>
                                                        Buy now
                                                    </button>
                                                    </Link>
                                                </div>
                                                <div className={`${plan === "Premium" ? "hr_active" : "hr_simple"}`} > </div>
                                                <div className="offers">
                                                    <ul>
                                                        <li>
                                                            Unlimited drivers
                                                        </li>
                                                        <li>
                                                            Unlimited trips
                                                        </li>
                                                        <li>
                                                            Unlimited bookings
                                                        </li>
                                                        <li>
                                                            Admin Commision
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className={`plans_div ${plan === "Pro" ? "plan_Active" : "plan_simple"}`} onClick={() => { setPlan("Pro") }}>
                                            <div className="">
                                                <h2>Pro</h2>
                                                <div className="d-flex">
                                                    <h5 className="me-1">${month === "Month" ? "120" : "540"}  </h5>/ <h5 className="ms-1">{month === "Month" ? "Month" : "Year"}</h5>
                                                </div>
                                                <div className="text-center">
                                                <Link to="/register">  <button className={`btn ${plan === "Pro" ? "buy_active" : "buy_simple"}`}>
                                                        Buy now
                                                    </button>
                                                    </Link>
                                                </div>
                                                <div className={`${plan === "Pro" ? "hr_active" : "hr_simple"}`} > </div>
                                                <div className="offers">
                                                    <ul>
                                                        <li>
                                                            Unlimited drivers
                                                        </li>
                                                        <li>
                                                            Unlimited trips
                                                        </li>
                                                        <li>
                                                            Unlimited bookings
                                                        </li>
                                                        <li>
                                                            No Admin Commision
                                                        </li>
                                                        <li>
                                                            One Time Pay
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default PricingHome